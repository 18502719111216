import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/HomeScreen.css'; 
import camera from './img/cam.png';
import safari from './img/saf.png';
import mail from './img/mail.png';
import contacts from './img/contacts.webp';
import calandar from './img/calandar.jpeg';
import maps from './img/maps.png';
import clock from './img/clock2.webp';
import photos from './img/photos.png';
import news from './img/news.png';
import wallet from './img/wallet.webp';
import notes from './img/notes.png';
import reminders from './img/reminders.png';
import stocks from './img/stocks.png';
import books from './img/Ibooks.png';
import health from './img/health.png';
import podcast from './img/podcast.png';
import facetime from './img/facetime.png';
import social from './img/social.webp';
import app from './img/app.png';
import HomeButton from './HomeButton';
import calculator from './img/calculator.png';
import colingpt from './img/colingpt.png';



const HomeScreen = () => {
  const navigate = useNavigate();

  const apps = [
    { id: 1, name: 'Safari', icon: safari},
    { id: 2, name: 'Mail', icon: mail, path: '/Mail' },
    { id: 3, name: 'Calendar', icon: calandar, path: '/Calander'  },
    { id: 4, name: 'Camera', icon: camera, backgroundColor: '#b7b7b7',path: '/camera' }
  ];

  const apps2 = [
    
    { id: 1, name: 'Contacts', icon: contacts, path: '/contacts'},
    { id: 2, name: 'Calculator', icon: calculator, backgroundColor: '#F89B18', path: '/calculator' },
    { id: 3, name: 'Clock', icon: clock, path: '/clock', backgroundColor: '#1E1E1E'},
    { id: 4, name: 'Photos', icon: photos, path: '/photos',  backgroundColor: 'white' }

  ];

  const apps3 = [
    { id: 1, name: 'News', icon: news },
    { id: 2, name: 'Wallet', icon: wallet, backgroundColor: '#1E1E1E' },
    { id: 3, name: 'Apps', icon: app},
    { id: 4, name: 'GMaps', icon: maps }
  ];

  const apps4 = [
    { id: 1, name: 'Notes', icon: notes, path: '/notes' },
    { id: 2, name: 'Reminders', icon: reminders, backgroundColor: 'lightgray' },
    { id: 3, name: 'Stocks', icon: stocks, backgroundColor: '#32363a' },
    { id: 4, name: 'Books', icon: books, backgroundColor: '#F6711B' }

  ];
  const apps5 = [
    { id: 1, name: 'ColinGPT', icon: colingpt },
    { id: 2, name: 'Health', icon: health, backgroundColor: '#e65974' },
    { id: 3, name: 'Facetime', icon: facetime },
    { id: 4, name: 'Podcasts', icon: podcast }

  ];

  const apps6 = [
    { id: 1, name: 'X', icon: social }
  ];

  const preloadImages = (srcArray) => {
    srcArray.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    const imagesToPreload = [
      camera, safari, mail, contacts, calandar, maps, clock, photos,
      news, wallet, notes, reminders, stocks, books, health, podcast, 
      facetime, social, app
      // ... list any additional image paths
    ];
  

  preloadImages(imagesToPreload);
 
}, []);
  
  const renderAppIcons = (appList) => (
    appList.map(app => {
      // Check if the app is GMaps, and if so, use an <a> tag instead of Link
      if (app.name === 'X') {
        return (
          <a href="https://x.com" key={app.id} className={`app-icon-container ${app.className || ''}`} target="_blank" rel="noopener noreferrer">
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </a>
        );
      } else if (app.name === 'Safari') {
        return (
          <a href="https://www.google.com" key={app.id} className={`app-icon-container ${app.className || ''}`} target="_blank" rel="noopener noreferrer">
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </a>
        );
      } else if (app.name === 'ColinGPT') {
        return (
          <a href="https://www.colinjcasey.com" key={app.id} className={`app-icon-container ${app.className || ''}`} target="_blank" rel="noopener noreferrer">
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </a>
        );
      } else if (app.name === 'News') {
        return (
          <a href="https://www.foxnews.com" key={app.id} className={`app-icon-container ${app.className || ''}`} target="_blank" rel="noopener noreferrer">
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </a>
        );
      } else if  (app.name === 'GMaps') {
        return (
          <Link to="/gmaps" key={app.id} className={`app-icon-container ${app.className || ''}`}>
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </Link>
        );
      } else {
        // For other apps, continue using the Link component
        return (
          <Link to={app.path || '/home'} key={app.id} className={`app-icon-container ${app.className || ''}`}>
            <div 
              className="app-icon" 
              style={{ 
                backgroundImage: `url(${app.icon})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundColor: app.backgroundColor || ''  
              }} 
            />
            <label className="app-label">{app.name}</label>
          </Link>
        );
      }
    })
  );
  
  
  

  return (
    <div className="home-screen">
      <div className="apps-grid">{renderAppIcons(apps)}</div>
      <div className="apps-grid">{renderAppIcons(apps2)}</div>
      <div className="apps-grid" >{renderAppIcons(apps3)}</div>
      <div className="apps-grid">{renderAppIcons(apps4)}</div>
      <div className="apps-grid">{renderAppIcons(apps5)}</div>
      <div className="apps-grid">{renderAppIcons(apps6)}</div>
    </div>
  );
};

export default HomeScreen;
